import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';
import { reducer, loadUser } from 'redux-oidc';
import userManager from "../auth/userManager";
import serviceWorkerReducer from './reducers/ServiceWorkerReducer'
import sideBarReducer from './reducers/SideBarReducer'
import userConfigStateReducer from './reducers/UserConfigStateReducer'
import nonPersistReducer from './reducers/NonPersistUpdatesReducer'
import registrationReducer from './reducers/RegistrationReducer'
import accountsReducer from './reducers/AccountsReducer'

export const history = createBrowserHistory()
const middleware = [routerMiddleware(history)];

const appReducer = combineReducers({
    accounts: accountsReducer,
    auth: reducer,
    serviceworker: serviceWorkerReducer,
    sideBar: sideBarReducer,
    userConfigState: userConfigStateReducer,
    nonPersistUpdates: nonPersistReducer,
    registration: registrationReducer,
    router: connectRouter(history)
})


  // reset the state of a redux store
  const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
       // for all keys defined in your persistConfig(s)
       storage.removeItem('persist:root')
       localforage.removeItem('persist:root')
  
       //storage.removeItem('persist:tasks')
      // localforage.removeItem('persist:tasks')
       // storage.removeItem('persist:otherKey')
       
       var authUrl = process.env.REACT_APP_ADROBOT_AUTHORIZATION_SERVER_URL
       var oidcKey = `oidc.user:${authUrl}:csweb`
       storage.removeItem(oidcKey)
       localforage.removeItem(oidcKey)
       sessionStorage.removeItem(oidcKey);
      // console.log(oidcKey, "Charless")
      state = undefined;
    }
    return appReducer(state, action)
  }

  const persistConfig = {
    key: 'root',
    storage: localforage,
    blacklist: ['router', 'serviceworker', "nonPersistUpdates"]
  }
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: persistedReducer,
    middleware,
  });

  loadUser(store, userManager);   

  export const persistor = persistStore(store);

  export default store;