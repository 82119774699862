import numFormat from 'number-format'
import moment from 'moment'
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

/// Check for Empty Object
export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export function removeArray(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export function compareArray(value, other) {

    if (!Array.isArray(value)) {
        console.log("Array 1 is not an array", value)
        return false
    }
    if (!Array.isArray(other)) {
        console.log("Array 2 is not an array", other)
        return false
    }
    if (!value || !other) {
        console.log("Both is not an array", other)
        return false
    }

    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    var compare = function (item1, item2) {

        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!compareArray(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }

        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;

};


export function compareArray2(arr1, arr2) {

    if (!Array.isArray(arr1)) {
        console.log("Array 1 is not an array", arr1)
        return false
    }
    if (!Array.isArray(arr2)) {
        console.log("Array 2 is not an array", arr2)
        return false
    }
    if (!arr1 || !arr2) {
        console.log("Both is not an array", arr2)
        return false
    }


    let result;

    arr1.forEach((e1, i) => arr2.forEach(e2 => {

        if (e1.length > 1 && e2.length) {
            result = compareArray(e1, e2);
        } else if (e1 !== e2) {
            result = false
        } else {
            result = true
        }
    })
    )

    return result

}

export function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function hasClass2(element, className) {
    return element.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(element.className);
}


export function toggleClass(el, className) {
    var pattern = new RegExp('(^|\\s)' + className + '(\\s|$)');
    if (pattern.test(el.className)) {
        el.className = el.className.replace(pattern, ' ');
    } else {
        el.className += ' ' + className;
    }
};



export function formatCurrency(Amount, format, code) {
    // console.log(format)
    //console.log(numFormat(format, Amount))
    var DecimalSeparator = Number("1.2").toLocaleString().substr(1, 1);
    var AmountWithCommas = Amount.toLocaleString();
    var arParts = String(AmountWithCommas).split(DecimalSeparator);
    var intPart = arParts[0];
    var decPart = (arParts.length > 1 ? arParts[1] : '');
    decPart = (decPart + '00').substr(0, 2);
    if (!code) {
        code = 'NGN '
    }
    if (format) {
        return numFormat(format, Amount)
    }

    return code + ' ' + intPart + DecimalSeparator + decPart;
}

export function checkComponentValidation(validations) {
    //console.log((validations))
    //console.log(Object.values(validations))
    const validation = {}
    var values = Object.values(validations);
    for (let o of values) {
        if (o.isValid === false) {
            return o;
        }
        else {
            return { isValid: true, message: "" }
        }
    }
}


export function clearSessionStorage() {

}

export function GetTimeInWords(value) {
    if (value <= 0) {
        return "0 secs";
    }
    var diff = moment.duration(value, 'minutes');
    if (diff.days() > 1) {
        return diff.days() + " days"
    }
    if (diff.days() === 1) {
        return diff.days() + " day"
    }
    if (diff.hours() > 1) {
        return diff.hours() + " hours"
    }
    if (diff.hours() === 1) {
        return diff.hours() + " hour"
    }
    if (diff.minutes() > 1) {
        return diff.minutes() + " mins"
    }
    if (diff.minutes() === 1) {
        return diff.minutes() + " min"
    }
    if (diff.minutes() < 1) {
        return "1 min"
    }
    return "0 secs";
}

export function titleCase(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}


export function toTitleCase(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateEmail(email) {
    if (isNullOrEmpty(email)) {
        return ""
    }
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const booststrapColors = {
    names: {
        primary: "#321fdb",
        secondary: "#ced2d8",
        success: "#2eb85c",
        danger: "#e55353",
        warning: "#f9b115",
        info: "#3399ff",
        // light: "#ebedef",
        dark: "#636f83",
    },
    foreColor: {
        primary: "#ffffff",
        secondary: "#000000",
        success: "#ffffff",
        danger: "#ffffff",
        warning: "#ffffff",
        info: "#ffffff",
        // light: "#000000",
        dark: "#ffffff",
    },
    random: () => {
        var result;
        var count = 0;
        for (var prop in booststrapColors.names)
            if (Math.random() < 1 / ++count)
                result = prop;
        return { name: result, rgb: booststrapColors.names[result], hex: booststrapColors.names[result], foreColor: booststrapColors.foreColor[result] };
    },
    getColor: (value = 0) => {
        if (value <= 0) {
            return { name: "primary", rgb: booststrapColors.names.primary, hex: SyncColors.names["primary"], foreColor: booststrapColors.foreColor['primary'] };
        }
        let colorKeys = Object.keys(booststrapColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        return { name: "danger", rgb: booststrapColors.names.danger, hex: booststrapColors.names["danger"], foreColor: booststrapColors.foreColor['danger'] };
    },
    getColorFromString: (stringValue = "null") => {
        if (isNullOrEmpty(stringValue)) {
            stringValue = "null";
        }

        var colors = Object.keys(booststrapColors.names)
        var isAvalaible = colors.findIndex(x => x === stringValue);
        if (isAvalaible >= 0) {
            return { name: stringValue, rgb: booststrapColors.names[stringValue], hex: booststrapColors.names[stringValue], foreColor: booststrapColors.foreColor[stringValue] };
        }
        var value = Math.abs(hashCode(stringValue));
        //console.log(value.toString().length)
        if (value.toString().length > 5) {
            value = parseInt(value.toString().substr(0, 2))
        }
        if (value <= 0) {
            return { name: "success", rgb: booststrapColors.names.success, hex: booststrapColors.names["success"], foreColor: booststrapColors.foreColor['success'] };
        }
        let colorKeys = Object.keys(booststrapColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        return { name: "danger", rgb: booststrapColors.names.danger, hex: booststrapColors.names["danger"], foreColor: booststrapColors.foreColor['danger'] };
    }
}


export const SyncColors = {
    names: {
        aqua: "#00ffff",
        black: "#000000",
        blue: "#0000ff",
        brown: "#a52a2a",
        cyan: "#00ffff",
        darkblue: "#00008b",
        darkcyan: "#008b8b",
        darkgrey: "#a9a9a9",
        darkgreen: "#006400",
        darkkhaki: "#bdb76b",
        darkmagenta: "#8b008b",
        darkolivegreen: "#556b2f",
        darkorange: "#ff8c00",
        darkorchid: "#9932cc",
        darkred: "#8b0000",
        darksalmon: "#e9967a",
        darkviolet: "#9400d3",
        fuchsia: "#ff00ff",
        gold: "#ffd700",
        green: "#008000",
        indigo: "#4b0082",
        khaki: "#f0e68c",
        lightblue: "#add8e6",
        lightgreen: "#90ee90",
        lightgrey: "#d3d3d3",
        lightpink: "#ffb6c1",
        lime: "#00ff00",
        magenta: "#ff00ff",
        maroon: "#800000",
        navy: "#000080",
        olive: "#808000",
        orange: "#ffa500",
        pink: "#ffc0cb",
        purple: "#800080",
        violet: "#800080",
        red: "#ff0000",
        silver: "#c0c0c0",
    },
    random: () => {
        var result;
        var count = 0;
        for (var prop in SyncColors.names)
            if (Math.random() < 1 / ++count)
                result = prop;
        console.log(result)
        //console.log(SyncColors.names.length)
        return { name: result, rgb: SyncColors.names[result], hex: SyncColors.names[result] };
    },
    getColor: (value = 0) => {
        if (value <= 0) {
            return { name: "green", rgb: SyncColors.names.green, hex: SyncColors.names["green"] };
        }
        let colorKeys = Object.keys(SyncColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color] };
        }

        return { name: "red", rgb: SyncColors.names.red, hex: SyncColors.names["red"] };
    },
    getColorFromString: (stringValue = "null") => {
        if (isNullOrEmpty(stringValue)) {
            stringValue = "null";
        }
        var value = Math.abs(hashCode(stringValue));
        //console.log(value.toString().length)
        if (value.toString().length > 5) {
            value = parseInt(value.toString().substr(0, 2))
        }
        if (value <= 0) {
            return { name: "green", rgb: SyncColors.names.green, hex: SyncColors.names["green"] };
        }
        let colorKeys = Object.keys(SyncColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color] };
        }

        return { name: "red", rgb: SyncColors.names.red, hex: SyncColors.names["red"] };
    }
}

function getlength(number) {
    return number.toString().length;
}

export function hashCode(s) {
    for (var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
}

export function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}


export function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    //dummy.style.display = 'none';
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

function toCamelCase(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
};

String.prototype.toCamelCase = function () {

    return this.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase()
        return !isNullOrEmpty(p1) ? p1.toLowerCase() : "";
    });
};

export function toPascalCase(string) {
    if (isNullOrEmpty(string)) {
        return ""
    }
    return `${string}`
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w+)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, 'g'), '')
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export function isNullOrEmpty(value) {
    return (!value);
}

export function parseBool(v) { return v === "false" || v === "False" || v === "null" || v === "NaN" || v === "undefined" || v === "0" ? false : !!v; }

// Object.prototype.isNullOrEmpty = function(value){
//     return (!value);
// }

export function retry(fn, retriesLeft = 5, interval = 1000, increaseInterval = false, incrementStep = 1) {
    return new Promise((resolve, reject) => {
        // console.log("Retrying", interval, incrementStep)
        if (increaseInterval) {
            interval = interval * incrementStep;
            incrementStep += incrementStep;
        }
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        console.log("Retrying maximum retries exceeded")
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }


                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval, increaseInterval, incrementStep).then(resolve, reject);
                }, interval);
            });
    });
}


export function download(dataurl, filename) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
    return false;
}

export function downloadFile(url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}


export const httpHelpers = {
    // Main wrapper for Fetch API
    httpRequest: (url, method, payload, headers) => {
        // Configuration to accept json as a default
        const config = {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        // method = post and payload, add it to the fetch request
        if (method.toLowerCase() === 'post' && payload && payload.length > 0) {
            config.body = JSON.stringify(payload);
        }
        // if custom headers need to be set for the specific request
        // override them here
        if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
            config.headers = headers;
        }
        return fetch(
            url,
            config
        ).then((response) => {

            // Check if the request is 200
            if (response.ok) {
                let data = response;

                // if the type is json return, interpret it as json
                if (response.headers.get('Content-Type').indexOf('application/json') > -1) {

                    data = response.json();
                }
                return data;
            }
            // if an errors, anything but 200 then reject with the actuall response
            return Promise.reject(response);
        });
    },
};

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function removeLeadingZero(value) {
    if (isNullOrEmpty(value)) {
        return ""
    }
    return value.replace(/^0+/, '')
}

export function hasLeadingZero(value) {
    if (isNullOrEmpty(value)) {
        return false
    }
    var matches = value.match(/^0+/g);
    if (matches === null) {
        return false
    }
    if (Array.isArray(matches)) {
        return matches.length > 0
    }
    return false
}

export function obscureEmail(emailString) {
    if (isNullOrEmpty(emailString)) {
        return emailString
    }
    var splitEmail = emailString.split("@")
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return name.substring(0, 3).concat("*********@").concat(domain)
}

export function obscureEmailSameLength(emailString) {
    if (isNullOrEmpty(emailString)) {
        return emailString
    }
    var splitEmail = emailString.split("@")
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return name.substring(0, 3).concat(Array(name.length - 3).join("*")).concat("@").concat(domain)
}

export function obscurePhoneNumber(phoneNumber) {
    if (isNullOrEmpty(phoneNumber)) {
        return phoneNumber
    }

    var last4Digits = phoneNumber.substring(phoneNumber.length - 4)

    return (Array(phoneNumber.length - 4).join("*")).concat(last4Digits)
}

export const findHeaderByUuid = (uuid) =>
    document.querySelector(`[data-header-key='${uuid}-header']`);

export const findRowByUuidAndKey = (uuid, key) =>
    document.querySelector(`[data-row-key='${uuid}-${key}']`);


export function isEven(x) { return (x % 2) === 0; }
export function isOdd(x) { return !isEven(x); }

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function isLikelyPhoneNumber(value) {
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im;
    return regex.test(value);
}

export function pagination(length, currentPage, itemsPerPage) {
    return {
        total: length,
        per_page: itemsPerPage,
        current_page: currentPage,
        last_page: Math.ceil(length / itemsPerPage),
        from: ((currentPage - 1) * itemsPerPage) + 1,
        to: currentPage * itemsPerPage
    };
};

export function checkInput(input, words) {
    if (isNullOrEmpty(input)) {
        return ""
    }
    if (isNullOrEmpty(words)) {
        return ""
    }
    return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
}


export function checkInputRegex(input, words) {
    return words.some(word => new RegExp(word, "i").test(input));
}

export function checkInputArray(input, words) {
    if (isNullOrEmpty(input)) {
        return ""
    }
    var result = []
    for (let index = 0; index < words.length; index++) {
        if (input.toLowerCase().indexOf(words[index].toLowerCase()) >= 0) {
            result.push(words[index])
        }
    }
    return result;
    //return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
}

export function checkInputStartArray(input, words) {
    if (isNullOrEmpty(input)) {
        return ""
    }
    var result = []
    for (let index = 0; index < words.length; index++) {
        if (input.toLowerCase().startsWith(words[index].toLowerCase())) {
            result.push(words[index])
        }
    }
    return result;
    //return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
}


export function getExpirationTime(expirationAt) {
    var jan1970 = new Date("1970-01-01T00:00:00Z");
    jan1970.setSeconds(jan1970.getSeconds() + expirationAt)
    return jan1970;
}

export function pad(num) {
    return ("0" + num).slice(-2);
}

export function frac(f) {
    return f % 1;
}

export function secondsToTime(secs) {
    const theSeconds = parseInt(secs, 10);
    var h = Math.floor(theSeconds / 3600).toString().padStart(2, '0'),
        m = Math.floor(theSeconds % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(theSeconds % 60).toString().padStart(2, '0');

    return h + ':' + m + ':' + s;
    //return `${h}:${m}:${s}`;
}

export function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export function mmss(secs) {
    // console.log(secs, "Seconds")
    if (secs < 0) {
        secs = 0;
    }
    var minutes = 0;
    if (secs > 59) {
        minutes = Math.floor(secs / 60);
        var actualMin = secs / 60;
        var fraction = Math.floor(frac(actualMin) * 100)
        if ((fraction) > 59) {
            minutes = minutes + (Math.floor(fraction / 60))
            secs = fraction % 60;
        } else {
            secs = fraction;
        }
    }

    return `${(minutes)}:${pad(secs)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export function minTohhmmss(min) {
    let secs = min * 60;
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export function minTommss(min) {
    let secs = min * 60;
    if (secs < 0) {
        secs = 0;
    }
    var minutes = 0;
    if (secs > 59) {
        minutes = Math.floor(secs / 60);
        var actualMin = secs / 60;
        var fraction = Math.floor(frac(actualMin) * 100)
        if ((fraction) > 59) {
            minutes = minutes + (Math.floor(fraction / 60))
            secs = fraction % 60;
        } else {
            secs = fraction;
        }
    }

    //var hours = Math.floor(minutes / 60);
    // minutes = minutes % 60;
    return `${(minutes)}:${pad(secs)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export function numberWithCommas(x) {
    if (isNullOrEmpty(x)) {
        if (x.toString() !== "0") {
            return ""
        }
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberToAmount(value, fixed = 2) {
    // const theValue = parseInt(value, 10);
    if (fixed <= 0) {
        return value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function fractionToPercentage(floatValue, fixed = 2) {
    let theValue = parseFloat(floatValue);
    theValue = theValue * 100;
    if (fixed <= 0) {
        return `${theValue.toFixed()}%`;
    }
    return `${theValue.toFixed(fixed)}%`;
    //return value.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function numberToPercentage(value, total, fixed = 2) {
    if (total <= 0) {
        return 0;
    }
    if (value <= 0) {
        return 0;
    }
    var rate = (value / total) * 100;
    if (fixed <= 0) {
        return `${rate.toFixed()}%`;
    }
    return `${rate.toFixed(fixed)}%`;
    // return rate.toFixed(fixed);
    //return value.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export function getRateValue(value, total, fixed = 2) {
    if (total <= 0) {
        return 0;
    }
    if (value <= 0) {
        return 0;
    }
    var rate = value / total;
    if (fixed <= 0) {
        return rate.toFixed();
    }
    return rate.toFixed(fixed);
}

export function truncJoin(array, max, add) {
    max = max || 3;
    add = add || ' ...';
    var remaining = (array.length - max);
    return (array.length > max ? array.splice(0, max).join(' , ') + add + remaining + ' more' : array.join(' , '));
}

export function numberScale(value) {
    if (value === null || value === undefined) {
        return 1000
    }

    if (value <= 1000) {
        return 1000;
    }

    if (value > 1000 && value <= 10000) {
        return 10000;
    }

    if (value > 10000 && value <= 100000) {
        return 100000;
    }

    if (value > 100000 && value <= 1000000) {
        return 1000000;
    }

    return 1000000;
}

export function scaleInterpretation(scale) {
    var actualScale = numberScale(scale)
    if (actualScale === 1000) {
        return 'x1000'
    }
    if (actualScale === 10000) {
        return 'x10000'
    }
    if (actualScale === 100000) {
        return 'x100000'
    }

    if (actualScale === 1000000) {
        return 'in millions'
    }
}

export const isDate = (date) => {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}


export function saveImg(imgUrl, imageName = "image_name", imageExtension = ".jpg") {
    if (window.navigator.msSaveOrOpenBlob) {
        // Intercept the data content of base64 (remove the previous description, such as data:image/png;base64) and decode it into binary data
        let bstr = atob(imgUrl.split(',')[1])
        // Gets the length of the decoded binary data, which is used to create the binary data container later
        let n = bstr.length
        // Create an array of type Uint8Array to hold binary data
        let u8arr = new Uint8Array(n)
        // Store binary data in an array of type Uint8Array
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        // Create blob object
        let blob = new Blob([u8arr])
        // Call the method of browser to start the download process of IE
        window.navigator.msSaveOrOpenBlob(blob, imageName + imageExtension)
    } else {//Similar to chrome browser, create an a-tag and download it with the a-tag's href attribute
        let tempA = document.createElement("a");
        tempA.download = imageName + imageExtension;
        tempA.href = imgUrl;
        document.body.appendChild(tempA);
        tempA.click();
        tempA.remove();
    }
}



export function dateRangePresetModes(searchInfo = {}) {
    var defaultSearchInfo = {
        minSearchDate: dayjs().subtract(105, 'days'),
        maxSearchDate: dayjs().subtract(15, 'days'),
        maxDateRangeDays: 92,
        infiniteMinSearchDate: false,
        lastApprovedDate: dayjs('2021-7-31'),
    };
    var actualOptions = { ...defaultSearchInfo, ...searchInfo };
    let presetModes = [];
    if (dayjs().isSame(actualOptions.maxSearchDate, 'day')) {
        presetModes.push({ label: 'Today', start: new Date(), end: new Date() })
    } else {
        presetModes.push({ label: 'Last Approved Day', start: dayjs(actualOptions.maxSearchDate).toDate(), end: dayjs(actualOptions.maxSearchDate).toDate() })
    }
    if (dayjs().subtract(1, 'days').isSameOrBefore(actualOptions.maxSearchDate)) {
        presetModes.push(...[{ label: 'Yesterday', start: new Date(new Date().setDate(new Date().getDate() - 1)), end: dayjs().subtract(1, 'days').toDate() }, { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
        { label: 'Last 14 days', start: new Date(new Date().setDate(new Date().getDate() - 14)), end: new Date() }])
    }
    if (dayjs().isSame(actualOptions.maxSearchDate, 'day')) {
        presetModes.push(...[{ label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
        { label: 'Last 60 days', start: new Date(new Date().setDate(new Date().getDate() - 60)), end: new Date() },
        { label: 'This Month', start: dayjs().startOf('month').toDate(), end: dayjs().endOf('month').toDate() },
        { label: 'Last Month', start: dayjs().subtract(1, 'month').startOf('month').toDate(), end: dayjs().subtract(1, 'month').endOf('month').toDate() },
        { label: 'Last 3 Months', start: dayjs().subtract(3, 'month').startOf('month').toDate(), end: dayjs().subtract(1, 'month').endOf('month').toDate() },])
    } else {
        presetModes.push(...[
            { label: 'Last Approved 7 days', start: dayjs(actualOptions.maxSearchDate).subtract(7, 'days').toDate(), end: dayjs(actualOptions.maxSearchDate).toDate() },
            { label: 'Last Approved 14 days', start: dayjs(actualOptions.maxSearchDate).subtract(14, 'days').toDate(), end: dayjs(actualOptions.maxSearchDate).toDate() },
            { label: 'Last Approved 30 days', start: dayjs(actualOptions.maxSearchDate).subtract(30, 'days').toDate(), end: dayjs(actualOptions.maxSearchDate).toDate() },
            { label: 'Last Approved 60 days', start: dayjs(actualOptions.maxSearchDate).subtract(60, 'days').toDate(), end: dayjs(actualOptions.maxSearchDate).toDate() },
            { label: 'This Approved Month', start: dayjs(actualOptions.maxSearchDate).startOf('month').toDate(), end: dayjs(actualOptions.maxSearchDate).toDate() },
            { label: 'Last Approved Month', start: dayjs(actualOptions.maxSearchDate).subtract(1, 'month').startOf('month').toDate(), end: dayjs(actualOptions.maxSearchDate).subtract(1, 'month').endOf('month').toDate() },
            { label: 'Last 3 Approved Months', start: dayjs(actualOptions.maxSearchDate).subtract(3, 'month').startOf('month').toDate(), end: dayjs(actualOptions.maxSearchDate).subtract(1, 'month').endOf('month').toDate() },])
    }
    return presetModes;
}

const dateRangePresetModes2 = [
    { label: 'Today', start: new Date(), end: new Date() },
    { label: 'Yesterday', start: new Date(new Date().setDate(new Date().getDate() - 1)), end: dayjs().subtract(1, 'days').toDate() },
    { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
    { label: 'Last 14 days', start: new Date(new Date().setDate(new Date().getDate() - 14)), end: new Date() },
    { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
    { label: 'Last 60 days', start: new Date(new Date().setDate(new Date().getDate() - 60)), end: new Date() },
    { label: 'This Month', start: dayjs().startOf('month').toDate(), end: dayjs().endOf('month').toDate() },
    { label: 'Last Month', start: dayjs().subtract(1, 'month').startOf('month').toDate(), end: dayjs().subtract(1, 'month').endOf('month').toDate() },
    { label: 'Last 3 Month', start: dayjs().subtract(3, 'month').startOf('month').toDate(), end: dayjs().subtract(1, 'month').endOf('month').toDate() },

]


export function calculateRefetchInterval(endDate) {
    if (endDate !== null && endDate !== undefined) {

        if (dayjs().isSame(endDate, "day")) {
            return 600000;
        }
        var diff = dayjs().diff(dayjs(endDate), 'days')
        if (diff === 1) {
            return 1200000;
        }
        if (diff === 2) {
            return 2400000;
        }
        if (diff === 3) {
            return 3000000;
        }
    }

    return 3600000;
}

export const getDatesBetweenDates = (startDate, endDate) => {
    let dates = []
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    while (theDate < endDate) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, endDate]
    return dates
}






