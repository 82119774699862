import React from "react"
import ContentLoader from "react-content-loader"

const CoreUIPhoneDashboardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={480}
    height={800}
    viewBox="0 0 480 800"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
     <rect x="148" y="6" rx="0" ry="0" width="86" height="29" /> 
    <rect x="4" y="5" rx="0" ry="0" width="24" height="6" /> 
    <circle cx="79" cy="9" r="2" /> 
    <rect x="4" y="16" rx="0" ry="0" width="24" height="6" /> 
    <rect x="4" y="28" rx="0" ry="0" width="24" height="6" /> 
    <circle cx="447" cy="26" r="21" /> 
    <rect x="4" y="55" rx="0" ry="0" width="1185" height="3" /> 
    <rect x="7" y="373" rx="0" ry="0" width="476" height="180" /> 
    <rect x="7" y="576" rx="0" ry="0" width="478" height="161" /> 
    <rect x="-8" y="767" rx="0" ry="0" width="774" height="39" /> 
    <rect x="10" y="758" rx="0" ry="0" width="107" height="4" /> 
    <rect x="176" y="758" rx="0" ry="0" width="109" height="4" /> 
    <rect x="7" y="79" rx="0" ry="0" width="466" height="128" /> 
    <rect x="7" y="225" rx="0" ry="0" width="469" height="128" />
  </ContentLoader>
)

CoreUIPhoneDashboardLoader.metadata = {
    name: 'Charles Ejedawe',
    github: 'carlospence',
    description: 'Dashboard pages',
    filename: 'CoreUI DashboardLoader',
  }

export default CoreUIPhoneDashboardLoader