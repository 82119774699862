import React from "react"
import ContentLoader from "react-content-loader"

const MobileDashboardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={767}
    height={800}
    viewBox="0 0 767 800"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="79" cy="9" r="2" /> 
    <rect x="4" y="55" rx="0" ry="0" width="1185" height="3" /> 
    <rect x="6" y="424" rx="0" ry="0" width="247" height="133" /> 
    <rect x="7" y="576" rx="0" ry="0" width="765" height="161" /> 
    <rect x="-8" y="767" rx="0" ry="0" width="774" height="39" /> 
    <rect x="10" y="758" rx="0" ry="0" width="107" height="4" /> 
    <rect x="176" y="758" rx="0" ry="0" width="109" height="4" /> 
    <rect x="7" y="79" rx="0" ry="0" width="764" height="111" /> 
    <rect x="5" y="207" rx="0" ry="0" width="755" height="41" /> 
    <rect x="5" y="262" rx="0" ry="0" width="755" height="41" /> 
    <rect x="7" y="315" rx="0" ry="0" width="755" height="41" /> 
    <rect x="5" y="368" rx="0" ry="0" width="755" height="41" /> 
    <rect x="260" y="423" rx="0" ry="0" width="247" height="133" /> 
    <rect x="514" y="424" rx="0" ry="0" width="247" height="133" /> 
    <circle cx="743" cy="12" r="6" /> 
    <circle cx="743" cy="28" r="6" /> 
    <circle cx="743" cy="45" r="6" />
    <circle cx="350" cy="12" r="6" /> 
    <circle cx="350" cy="28" r="6" /> 
    <circle cx="350" cy="45" r="6" />
  </ContentLoader>
)

MobileDashboardLoader.metadata = {
    name: 'Charles Ejedawe',
    github: 'carlospence',
    description: 'Dashboard pages',
    filename: 'CoreUI DashboardLoader',
  }

export default MobileDashboardLoader