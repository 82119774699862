import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'

export const initialState = {
    lastUsersListSortMode: "Oldest",
    lastReportDateSelectionType: "DateRange",
    dashboardLastUpdate: null,
    dashboardDataLastUpdate: null,
    lastAction: null,
    initialization: null,
    lastRoute: "",
    modalOpened: false,
    closeAllModals: false,
    modalStates: [],
    isLoggedIn: false,
    lastDashboardQuery: {
        searchDateFrom: "",
        searchDateTo: "",
        containerGuid: "",
        campaignExisting: true,
        maxChartLabelCount: 1
    },


};


const userConfigStateSlice = createSlice({
    name: 'userConfigState',
    initialState,
    reducers: {
        setModalOpened: (state,  { payload }) => {
            //  console.log(payload, "setting Modal Opened State")
             return { ...state, modalOpened : payload  }
         },
         setCloseAllModals: (state,  { payload }) => {
            // console.log(payload, "setting Close All Modals State")
            return { ...state, closeAllModals : payload  }
        },
        setLastUsersListSortMode: (state,  { payload }) => {
            return { ...state, lastUsersListSortMode : payload  }
        },
        setLastReportDateSelectionType: (state,  { payload }) => {
            return { ...state, lastReportDateSelectionType : payload  }
        },
       
        setDashboardLastUpdate: (state,  { payload }) => {
            return { ...state, dashboardLastUpdate : payload  }
        },
        setDashboardDataLastUpdate: (state,  { payload }) => {
            return { ...state, dashboardDataLastUpdate : payload  }
        },
        setLastAction: (state,  { payload }) => {
            return { ...state, lastAction : payload  }
        },
        setInitialization: (state,  { payload }) => {
            return { ...state, initialization : payload  }
        },
        setLastRoute: (state,  { payload }) => {
            return { ...state, lastRoute : payload  }
        },
        addOrUpdateModalStates: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.url !== null && payload.url !== undefined && state.modalStates !== undefined) {
                let amExisting = state.modalStates.findIndex((element) => {
                    return element.url.toLowerCase() === payload.url.toLowerCase() && element.key.toLowerCase() === payload.key.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.modalStates[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.modalStates.unshift(payload)
                }


            }
           
        },
        removeModalState: (state, { payload }) => {
            var newModalStates = state.modalStates.filter(x => {
                return x.key.toLowerCase() !== payload.key.toLowerCase() && x.url.toLowerCase() !== payload.url.toLowerCase();
            })
            return { ...state, modalStates: newModalStates }
        },
        removeModalStateByUrl: (state, { payload }) => {
            var newModalStates = state.modalStates.filter(x => {
                return x.url.toLowerCase() !== payload.url.toLowerCase();
            })
            return { ...state, modalStates: newModalStates }
        },
        keepModalStateByUrl: (state, { payload }) => {
            var newModalStates = state.modalStates.filter(x => {
                return x.url.toLowerCase() === payload.url.toLowerCase();
            })
            return { ...state, modalStates: newModalStates }
        },
        clearModalStates: (state) => {

            return { ...state, modalStates: [] }
        },
        setIsLoggedIn: (state,  { payload }) => {
            console.log("Log in payload", payload)
             return { ...state, isLoggedIn : payload  }
         },
         setLastDashboardQuery: (state,  { payload }) => {
            return { ...state, lastDashboardQuery : payload  }
        },
    }
});



// The Actions
export const {setLastUsersListSortMode,
setLastReportDateSelectionType, setDashboardLastUpdate, setDashboardDataLastUpdate, setLastDashboardQuery,
setLastAction, setInitialization, setLastRoute, setModalOpened, setCloseAllModals, addOrUpdateModalStates, clearModalStates, 
removeModalState, removeModalStateByUrl, keepModalStateByUrl, setIsLoggedIn } = userConfigStateSlice.actions;

// A selector
export const userConfigStateSelector = state => state.userConfigState

// The reducer
export default userConfigStateSlice.reducer
