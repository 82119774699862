import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'
import { retry } from './utils/SyncUtils'
import { ErrorFallback } from './components/UtilComponents';
// import Main from './home/TestMain'
// import DefaultLayout from './containers/TheLayout'
import { SecuredRoute } from './auth/components'
import logo from './assets/images/amdlogo.png'


const DefaultLayout = React.lazy(() => retry(() => import('./containers/TheLayout')));
const SettingsLayout = React.lazy(() => retry(() => import('./containers/TheSettingsLayout')));
const NoSideBarLayout = React.lazy(() => retry(() => import('./containers/TheNoSideBarLayout')));
// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
const Login = React.lazy(() => import('./auth/components/Login'));
//const Register = React.lazy(() => import('./views/pages/register/Register'));
const Register = React.lazy(() => import('./auth/components/RegisterUserForm'));
const CodeVerification = React.lazy(() => retry(() => import('./auth/pages/signupcodeverification')));
const Main = React.lazy(() => retry(() => import('./home/Main')));
const Callback = React.lazy(() => retry(() => import('./auth/components/Callback')));
const SilentRenew = React.lazy(() => retry(() => import('./auth/components/SilentRenew')));

// const TestCodeVerification = React.lazy(() => retry(() => import('./auth/pages/testcodeverification')));
// const TestCodeVerificationCompleted = React.lazy(() => retry(() => import('./auth/pages/testverificationcompleted')));


function AppRoutes(props) {

    const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    const loader = (
        <div className="full-page-loader">
            <strong>Loading...</strong>
            <img src={logo} className="img-avatar" width="128px" alt="Medialytics Logo" />
        </div>)



    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Switch>


                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
                {/* <Route exact path="/signup" name="SignUp Page" render={props => <Register {...props} />} /> */}
                <Route exact path="/verify-code" name="Code Verification" render={props => <CodeVerification {...props} />} />
                {/* <Route exact path="/test-verify" name="Test Code Verification" render={props => <TestCodeVerification {...props} />} /> */}
                {/* <Route exact path="/test-verify-completed" name="Test Code Verification Completed" render={props => <TestCodeVerificationCompleted {...props} />} /> */}
                {/* <Route path="/login">
                    <Login />
                </Route> */}
                <Route name="Register Page" path="/register">
                    <Suspense fallback={loader}>
                        <Register  {...props} />
                    </Suspense>
                </Route>
                <Route name="SignUp Page" path="/signup">
                <Suspense fallback={loader}>
                        <Register  {...props} />
                    </Suspense>
                </Route>

                <Route name="Code Verification" path="/verify-code">
                <Suspense fallback={loader}>
                        <CodeVerification  {...props} />
                    </Suspense>
                </Route>
                <Route path="/callback">
                    <Suspense fallback={loader}>
                        <Callback />
                    </Suspense>

                </Route>
                {/* <Route path="/silent_callback">
                    <SilentRenew />
                </Route> */}
                {/* <Route path="/silent_renew.html">
                    <SilentRenew />
                </Route> */}
                <Route path="/silent_renew">
                    <SilentRenew />
                </Route>

                {/* <Route exact path="/register" name="Register Page" render={props => <SignUp {...props} />} />
                <Route exact path="/signup" name="SignUp Page" render={props => <SignUp {...props} />} /> */}

                {/* <SecuredRoute path="/dashboard">
                    <DefaultLayout {...props} />
                </SecuredRoute> */}
                <SecuredRoute path="/dashboard">
                    <Suspense fallback={loader}>
                        <DefaultLayout {...props} />
                    </Suspense>

                </SecuredRoute>
                {/* <SecuredRoute path="/dashboard2">
                    <DefaultLayout {...props} />
                </SecuredRoute> */}
                <SecuredRoute path="/console">
                    <Suspense fallback={loader}>
                        <DefaultLayout {...props} />
                    </Suspense>

                </SecuredRoute>
                <SecuredRoute path="/settings">
                    <Suspense fallback={loader}>
                        <SettingsLayout {...props} />
                    </Suspense>

                </SecuredRoute>
                <SecuredRoute path="/account">
                    <Suspense fallback={loader}>
                        <NoSideBarLayout {...props} />
                    </Suspense>

                </SecuredRoute>
                <Route path="/">
                    <Suspense fallback={loader}>
                        <Main />
                        {/* <DefaultLayout {...props} /> */}
                    </Suspense>

                </Route>
            </Switch>
        </ErrorBoundary>
    )

}

export default AppRoutes;