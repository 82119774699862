import { createSlice } from '@reduxjs/toolkit'
import {array_move, isEmpty} from '../../utils/SyncUtils'
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime)

export const defaultAccountSkeleton = {
    accountType: "",
    userProfile: {},
    containerUser: "",
    currentContainerIndex: -1,
    currentContainer: {},
    containers: [],
    currentDashboardData: {
        availableCampaigns: 0,
    },
    campaignActivitiesData: [],
    subscription: {},
    searchInfo: {
        minSearchDate: dayjs().subtract(105, 'days').toDate(),
        maxSearchDate: dayjs().subtract(15, 'days').toDate(),
        maxDateRangeDays: 92,
        infiniteMinSearchDate: false,
        lastApprovedDate: dayjs('2021-7-31').toDate(),
    }
}

export const initialState = {
   allAccounts: [],
   currentAccountUser: "",
};




const AccountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        setCurrentAccountUser: (state, { payload }) => {
            var serializedSkeleton = JSON.parse(JSON.stringify(defaultAccountSkeleton));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length <= 0){
                serializedSkeleton.containerUser = payload
                theAccounts.unshift(serializedSkeleton)
                //console.log("AM NOT EXISTING, CREATE ME")
            }
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload;
                })
                if (amExisting < 0) {
                    serializedSkeleton.containerUser = payload
                    theAccounts.unshift(serializedSkeleton)
                    //console.log("AM NOT EXISTING IN ACCOUNTS, CREATE ME") 
                  
                } else {
                    //console.log("I HAVE BEEN CREATED, THANK YOU") 
                }
            }
            return { ...state, currentAccountUser: payload, allAccounts: theAccounts }
        },
        setAccounts: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))
            var serializedSkeleton = JSON.parse(JSON.stringify(defaultAccountSkeleton));
            
            return { ...state, allAccounts: serializedPayload.containers }
        },
        updateAccountContainers: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                //console.info("I am Existing", payload.user)
                if (amExisting >= 0) {
                  theAccounts[amExisting].containers = serializedPayload.containers
                    //console.info("Assign Containers", serializedPayload.containers)
                  if (theAccounts[amExisting].currentContainer && isEmpty(theAccounts[amExisting].currentContainer)) {
                    if (serializedPayload.containers && Array.isArray(serializedPayload.containers) && serializedPayload.containers.length > 0)
                    theAccounts[amExisting].currentContainer = serializedPayload.containers[0]; 
                    theAccounts[amExisting].currentContainerIndex = 0
                  } else if (theAccounts[amExisting].currentContainerIndex >= 0) {
                      var currentIndex =  theAccounts[amExisting].currentContainerIndex;
                    if (serializedPayload.containers.length > currentIndex){
                        theAccounts[amExisting].currentContainer = serializedPayload.containers[currentIndex]; 
                    }
                  }
                } else {
                    //console.info("Am not existing, please add me", payload.user)
                }
            } else {
                //console.log("No Accounts available")
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateAccountContainer: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {

                  var userContainers = theAccounts[amExisting].containers;

                  var containerExisting = userContainers.findIndex(x => x.guid === serializedPayload.container.guid) ;
                  if (containerExisting >= 0) {
                    //   console.log("Updating account container")
                    theAccounts[amExisting].containers[containerExisting] = serializedPayload.container;
                    theAccounts[amExisting].currentContainerIndex = containerExisting;
                    theAccounts[amExisting].currentContainer = serializedPayload.container; 
                    // console.log("Finished Updating account container")
                  }
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateAccountUserProfile: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                  theAccounts[amExisting].userProfile = serializedPayload.userProfile
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerDashboardData: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                  var userContainers = theAccounts[amExisting].containers;

                  var containerExisting = userContainers.findIndex(x => x.guid === serializedPayload.containerGuid) ;
                  if (containerExisting >= 0) {
                    //   console.log("Updating account container")
                    //theAccounts[amExisting].containers[containerExisting] = serializedPayload.container;
                    //theAccounts[amExisting].currentContainerIndex = containerExisting;
                    var currentDashboardData = theAccounts[amExisting].currentDashboardData;
                    var dashbordData = {...currentDashboardData, ...serializedPayload.dashboardData }
                    theAccounts[amExisting].currentDashboardData = dashbordData; 
                    //console.log("Finished Updating account container")
                  }
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerDashboardLogs: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                  //var dashboardData = theAccounts[amExisting].currentDashboardData;
                 // var serializedData = 
                 var newLogs = theAccounts[amExisting].currentDashboardData.logs.concat(serializedPayload.logs);
                  theAccounts[amExisting].currentDashboardData.logs = newLogs;
                  
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerDashboardAvailableCampaigns: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                 
                  theAccounts[amExisting].currentDashboardData["availableCampaigns"] = serializedPayload.availableCampaigns;
                  
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerCampaignActivitiesData: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                  var userContainers = theAccounts[amExisting].containers;

                  var containerExisting = userContainers.findIndex(x => x.guid === serializedPayload.containerGuid) ;
                  if (containerExisting >= 0) {
                    theAccounts[amExisting].campaignActivitiesData = serializedPayload.campaignActivitiesData; 
                  }
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerSearchInfo: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                  var userContainers = theAccounts[amExisting].containers;

                  var containerExisting = userContainers.findIndex(x => x.guid === serializedPayload.containerGuid) ;
                  if (containerExisting >= 0) {
                    theAccounts[amExisting].searchInfo = serializedPayload.searchInfo; 
                  }
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerDashboardBrandTrends: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                 
                  theAccounts[amExisting].currentDashboardData["brandTrends"] = serializedPayload.brandTrends;
                  
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
        updateContainerDashboardStationTrends: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload));
            var theAccounts = JSON.parse(JSON.stringify(state.allAccounts));
            if (Array.isArray(state.allAccounts) && state.allAccounts.length > 0){
                let amExisting = state.allAccounts.findIndex(x => {
                    return x.containerUser === payload.user;
                })
                if (amExisting >= 0) {
                 
                  theAccounts[amExisting].currentDashboardData["stationTrends"] = serializedPayload.stationTrends;
                  
                } 
            }
            return { ...state, allAccounts: theAccounts }
        },
       
    }
});



// The Actions
export const { setCurrentAccountUser, updateAccountContainers, updateAccountContainer, updateAccountUserProfile,  updateContainerSearchInfo,
    updateContainerDashboardData, updateContainerDashboardLogs, updateContainerDashboardAvailableCampaigns, updateContainerCampaignActivitiesData,
updateContainerDashboardBrandTrends, updateContainerDashboardStationTrends } = AccountsSlice.actions;

// A selector
export const AccountsSelector = state => state.accounts

// The reducer
export default AccountsSlice.reducer
