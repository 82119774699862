import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    sidebarShow: 'responsive',
    minimize: true
};


const sideBarSlice = createSlice({
    name: 'sideBar',
    initialState,
    reducers: {
        set: (state,  { payload }) => {
           // console.log(payload, "charles")
            return { ...state, sidebarShow : payload  }
        },
        setMinimize: (state,  { payload }) => {
            // console.log(payload, "charles")
             return { ...state, minimize : payload  }
         }
    }
});



// The Actions
export const { set, setMinimize } = sideBarSlice.actions;

// A selector
export const sidebarShowSelector = state => state.sidebarShow

// The reducer
export default sideBarSlice.reducer
