import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'


export const initialState = {

    lastRoute: "",
    updateDashboardLogs: false,
    isDownloadingDashboardLogs: false,
    isDownloadingLogs: false,
    dashboardLogsUpdate: [],
    dashboardTableHistory: {
        saveCampaignPage: false,
        lastCampaignPage: 1,
        saveBrandPage: false,
        lastBrandPage: 1,
        saveStationPage: false,
        lastStationPage: 1,
    }

};

const nonPersistUpdatesSlice = createSlice({
    name: 'nonPersistUpdates',
    initialState,
    reducers: {
        
        addDashboardLogsUpdate: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))
            var newLogs = JSON.parse(JSON.stringify(state.dashboardLogsUpdate))
            if (Array.isArray(payload)) {
                newLogs  =  state.dashboardLogsUpdate.concat(serializedPayload)
            }
            return { ...state, dashboardLogsUpdate : newLogs  }
        },
        setLastRoute: (state,  { payload }) => {
            return { ...state, lastRoute : payload  }
        },
        setUpdateDashboardLogs: (state,  { payload }) => {
            return { ...state, updateDashboardLogs : payload  }
        },
       
        setIsDownloadingDashboardLogs: (state,  { payload }) => {
            return { ...state, isDownloadingDashboardLogs : payload  }
        },
        setIsDownloadingLogs: (state,  { payload }) => {
            return { ...state, isDownloadingLogs : payload  }
        },
        setUpdateDashboardTableHistory: (state,  { payload }) => {
            var currentState = {...state.dashboardTableHistory, ...payload}
            return { ...state, dashboardTableHistory : currentState  }
        },

    }

})

export const { setLastRoute, addDashboardLogsUpdate, setUpdateDashboardLogs,
setIsDownloadingDashboardLogs, setUpdateDashboardTableHistory, setIsDownloadingLogs } = nonPersistUpdatesSlice.actions;

// A selector
export const nonPersistSelector = state => state.nonPersistUpdate

// The reducer
export default nonPersistUpdatesSlice.reducer