import React from "react"
import ContentLoader from "react-content-loader"

const CoreUITabletDashboardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={767}
    height={1024}
    viewBox="0 0 767 1024"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
     <rect x="335" y="5" rx="0" ry="0" width="86" height="29" /> 
    <rect x="4" y="5" rx="0" ry="0" width="24" height="6" /> 
    <circle cx="79" cy="9" r="2" /> 
    <rect x="4" y="16" rx="0" ry="0" width="24" height="6" /> 
    <rect x="4" y="28" rx="0" ry="0" width="24" height="6" /> 
    <circle cx="743" cy="21" r="21" /> 
    <rect x="4" y="55" rx="0" ry="0" width="1185" height="3" /> 
    <rect x="7" y="440" rx="0" ry="0" width="764" height="217" /> 
    <rect x="5" y="687" rx="0" ry="0" width="759" height="215" /> 
    <rect x="7" y="982" rx="0" ry="0" width="774" height="39" /> 
    <rect x="248" y="935" rx="0" ry="0" width="107" height="4" /> 
    <rect x="486" y="934" rx="0" ry="0" width="109" height="4" /> 
    <rect x="7" y="108" rx="0" ry="0" width="350" height="128" /> 
    <rect x="403" y="259" rx="0" ry="0" width="350" height="128" /> 
    <rect x="404" y="107" rx="0" ry="0" width="350" height="128" /> 
    <rect x="6" y="260" rx="0" ry="0" width="350" height="128" />
  </ContentLoader>
)

CoreUITabletDashboardLoader.metadata = {
    name: 'Charles Ejedawe',
    github: 'carlospence',
    description: 'Dashboard pages',
    filename: 'CoreUI DashboardLoader',
  }

export default CoreUITabletDashboardLoader