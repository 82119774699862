import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'

export const initialState = {
    email: '',
    phoneNumber: '',
    users: []
};


const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        set: (state, { payload }) => {
            // console.log(payload, "charles")
            return { ...state, email: payload.email, phoneNumber: payload.phoneNumber }
        },
        reset: (state) => {
            // console.log(payload, "charles")
            return { ...state, email: '', phoneNumber: '', users: [] }
        },
        addOrUpdateUsers: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.email !== null && payload.email !== undefined && payload.phoneNumber !== null && payload.phoneNumber !== undefined && state.users !== undefined) {
                let amExisting = state.users.findIndex((element) => {
                    return element.email.toLowerCase() === payload.email.toLowerCase() && element.phoneNumber.toLowerCase() === payload.phoneNumber.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.users[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.
                    }
                }
                else {
                    state.users.unshift(payload)
                }

            }
        },
        removeUser: (state, { payload }) => {
            var newUsers = state.users.filter(x => {
                return x.email.toLowerCase() !== payload.email.toLowerCase() && x.phoneNumber.toLowerCase() !== payload.phoneNumber.toLowerCase();
            })
            return { ...state, users: newUsers }
        },
        clearUsers: (state) => {

            return { ...state, users: [] }
        },
    }
});



// The Actions
export const { set, reset, addOrUpdateUsers, clearUsers, removeUser } = registrationSlice.actions;

// A selector
export const registrationSelector = state => state.registration

// The reducer
export default registrationSlice.reducer