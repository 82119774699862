import React from "react"
import ContentLoader from "react-content-loader"

const ItemLoader = (props) => (
  <ContentLoader 
  
  speed={2}
  width={370}
  height={124}
  viewBox="0 0 370 124"
  backgroundColor="#d7d0d0"
  foregroundColor="#ecebeb"
  {...props}
>
  <rect x="191" y="11" rx="3" ry="3" width="180" height="7" /> 
  <rect x="54" y="33" rx="3" ry="3" width="410" height="7" /> 
  <rect x="54" y="57" rx="3" ry="3" width="410" height="6" /> 
  <rect x="54" y="74" rx="3" ry="3" width="380" height="6" /> 
  <rect x="54" y="92" rx="3" ry="3" width="126" height="7" /> 
  <circle cx="27" cy="20" r="20" /> 
  <rect x="249" y="93" rx="3" ry="3" width="126" height="7" /> 
  <rect x="62" y="6" rx="0" ry="0" width="20" height="16" />
  </ContentLoader>
)

ItemLoader.metadata = {
    name: 'Charles Ejedawe',
    github: 'carlospence',
    description: 'Item Loader',
    filename: 'Item Loader',
  }

export default ItemLoader
