import React from 'react'
import {
  CSpinner as Spinner

} from '@coreui/react'
import logo from '../assets/images/amdlogo.png'
import {ThreeDotsLoader } from '../skeletons'

export const TableCaption = (props) => {

  return (
<div className="group-caption">{props.caption || ""}</div>
  )

}

export const UserTableCaption = (props) => {

return (
<div className="user-caption">{props.caption || ""}</div>
)

}

export const EmptyRowsView = (props) => {
  const message = props.message || "No data to show at the moment..";
  return (
      <div
          style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px 25px 100px 25px" }}
      >
          {/* <img src={NoData} alt={message} height="35px" className="mb-2"/> */}
          <h5>{message}</h5>
      </div>
  );
};

export const LoadingRowsView = (props) => {
const message = props.message || "Still Loading, please wait..";
return (
    <div
        style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
    >
        <Spinner  color="danger" className="ml-2 mr-3 mb-3" />
        <h4>{message}</h4>
    </div>
);
};

export const LoadingSpinner = ({ size = "sm", color = "white", message = 'Loading...' , ...props}) => (
  <div  {...props}>
   <div className="mr-3">
   <Spinner size={size} color={color} className="ml-2 mr-3" />
   {message}
   </div>
  
  </div>
)

export const SimpleLoadingSpinner = ({size = "sm", color = "", ...props}) => (
<div style={{ width: '100%', height: '100%' }} className="animated fadeIn pt-1 text-center">
  <Spinner size={size} color={color}></Spinner>

  </div>)

export const LoadingSpinnerWithText = ({size = "md", color = "danger", message = '' , ...props}) => (
<div style={{ width: '100%', height: '100%' }}  className="animated fadeIn pt-1 text-center" {...props}>
  <Spinner size={size} color={color}></Spinner>
  <div>
  {message}
  </div>
 
  </div>)


export const SuspenseLoader = () => (
  <div className="text-center full-page-loader">
  <ThreeDotsLoader></ThreeDotsLoader>
  <img src={logo} className="img-avatar" width="128px" alt="Medialytics Logo"/>
  </div>
)

export const SuspenseLoaderWithText = ({showAnimation = true, message = '' , ...props}) => (
  <div className="text-center full-page-loader" {...props}>
 {showAnimation && <ThreeDotsLoader></ThreeDotsLoader>} 
  <strong>{message}</strong>
  <img src={logo} className="img-avatar" width="128px" alt="Medialytics Logo"/>
  </div>
)

export const UpdateServiceWorker = ({ closeToast, message = 'There is a new version available.', buttonText = "Update", onClick }) => (
  <div>
   <div className="">
   {message} {buttonText && <button className="btn btn-success btn-sm" onClick={onClick}>{buttonText}</button>}
   </div>
  
  </div>
)

export const InstallPWA = ({ closeToast, message = 'Install soWin App.', buttonText = "Install", onClick }) => (
  <div>
   <div className="">
   {message} {buttonText && <button className="btn btn-warning btn-sm" onClick={onClick}>{buttonText}</button>}
   </div>
  
  </div>
)

export const NotificationPermission = ({ closeToast, message = 'Receive notifications from soWin and your Groups', buttonText = "Accept", onClick }) => (
  <div>
   <div className="">
   {message} {buttonText && <button className="btn btn-warning btn-sm" onClick={onClick}>{buttonText}</button>}
   </div>
  
  </div>
)

export const LoadingAlertConfig = {  position: 'top-center', 
  autoClose: false, 
  closeButton: false,
   className:'toast-container-alert'}

export const FallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
    return (
      <div>
        <h1>An error occurred: {error.message}</h1>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  };
  
  export function ErrorFallback({error, componentStack, resetErrorBoundary}) {
    return (
      <div role="alert">
        <p>Something went wrong: Click on Try again below or Refresh this page</p>
        <pre style={{color: 'red'}}>{error.message}</pre>
        <pre>{componentStack}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }