import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
// import './assets/scss/style.scss';
import { useMediaQuery } from 'react-responsive'
import './components/styles.css'
import { ConnectedRouter } from 'connected-react-router'
import AppRoutes from './AppRoutes'
import { Toaster } from 'react-hot-toast';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faFacebookF, faTwitter, faYoutube, faYoutubeSquare, faLinkedin, faLinkedinIn, faWhatsapp, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import { history } from './redux/store'
import { CoreUIDashboardLoader, CoreUIPhoneDashboardLoader, CoreUITabletDashboardLoader } from './skeletons'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from "react-query/devtools";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
library.add(fas, faFacebookF, faFacebook, faTwitter, faYoutube, faYoutubeSquare, faLinkedinIn, faLinkedin, faWhatsappSquare, faWhatsapp)
// Create a client
const queryClient = new QueryClient()

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const toastOptions = {
  // Define default options
  className: '',
  duration: 5000,
  style: {
    background: '#363636',
    color: '#fff',
  },
  // Default options for specific types
  success: {
    duration: 3000,
    theme: {
      primary: 'green',
      secondary: 'black',
    },
  },
}

// class App extends Component {

//   render() {
//     return (
//       <ConnectedRouter history={history}>
//         <QueryClientProvider client={queryClient}>
//           <Toaster toastOptions={toastOptions}></Toaster>
//           <React.Suspense fallback={loading}>
//             <Switch>
//               {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
//               <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
//               <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
//               <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
//               <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
//               <AppRoutes>

//               </AppRoutes>
//             </Switch>
//           </React.Suspense>
//           <ReactQueryDevtools initialIsOpen={false} />
//         </QueryClientProvider>
//       </ConnectedRouter>
//     );
//   }
// }


function App() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isPhone = useMediaQuery({ maxWidth: 480 })

  const phoneLoader = (<div className="text-center">
<CoreUIPhoneDashboardLoader></CoreUIPhoneDashboardLoader>
</div>)

const mobileLoader = (<div className="text-center">
<CoreUITabletDashboardLoader></CoreUITabletDashboardLoader>
</div>)

const loader = (<div className="text-center">
<CoreUIDashboardLoader></CoreUIDashboardLoader>
</div>)

  return (
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <Toaster toastOptions={toastOptions}></Toaster>
        <React.Suspense fallback={isMobile ?  isPhone ? phoneLoader : mobileLoader: loader}>
          <Switch>

            <AppRoutes>

            </AppRoutes>
          </Switch>
        </React.Suspense>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConnectedRouter>
  )
}

export default App;
