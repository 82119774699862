import React from 'react';
import { useSelector } from "react-redux";
import {
    Route,
    Redirect
  } from "react-router-dom";

const SecuredRoute = ({ children, ...rest }) => {
   const stateAuth = useSelector(state => state.auth);
    const user = stateAuth.user;


    if (stateAuth.isLoadingUser) {
        return (
            <div>Loading...</div>
        );
    }

    // return (
    //     <Route
    //       {...rest}
    //       render={({ location }) =>
    //       (
    //         children
    //       )
    //       }
    //     />
    //   );

    return (
      <Route
        {...rest}
        render={({ location }) =>
           user  ? (
            children
          ) : (
            <Redirect
              to={{
                // pathname: "/relogin",
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  };

  export default SecuredRoute;