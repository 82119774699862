import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'
import 'pace-js'
import 'pace-js/themes/red/pace-theme-minimal.css'

import { Provider } from 'react-redux'
// import store from './store'
import store, { persistor } from './redux/store';
import { OidcProvider } from "redux-oidc";
import userManager from "./auth/userManager";
import { PersistGate } from 'redux-persist/integration/react'
import { setup } from 'goober';

React.icons = icons
const customPrefixer = (key, value) => `${key}: ${value};\n`;

setup(React.createElement, customPrefixer);

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </OidcProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
