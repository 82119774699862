import { createSlice } from '@reduxjs/toolkit'
import { array_move } from '../../utils/SyncUtils'

export const initialState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
};


const serviceWorkerSlice = createSlice({
    name: 'serviceworker',
    initialState,
    reducers: {
        setServiceWorkerInit: (state, { payload }) => {
            return { ...state, serviceWorkerInitialized: !state.serviceWorkerInitialized }
        },
        setServiceWorkerUpdate: (state, { payload }) => {
            return { ...state,
                serviceWorkerUpdated: !state.serviceWorkerUpdated, 
                serviceWorkerRegistration: payload }
        },
    }
});



// The Actions
export const { setServiceWorkerInit, setServiceWorkerUpdate } = serviceWorkerSlice.actions;

// A selector
export const serviceWorkerSelector = state => state.serviceworker

// The reducer
export default serviceWorkerSlice.reducer
