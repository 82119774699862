import React from "react"
import ContentLoader from "react-content-loader"

const CoreUIDashboardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={900}
    viewBox="0 0 1200 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="3" y="2" rx="0" ry="0" width="59" height="29" /> 
    <rect x="83" y="5" rx="0" ry="0" width="24" height="6" /> 
    <circle cx="79" cy="9" r="2" /> 
    <rect x="83" y="14" rx="0" ry="0" width="24" height="6" /> 
    <rect x="83" y="23" rx="0" ry="0" width="24" height="6" /> 
    <rect x="124" y="13" rx="0" ry="0" width="44" height="6" /> 
    <rect x="185" y="14" rx="0" ry="0" width="54" height="5" /> 
    <circle cx="1169" cy="22" r="21" /> 
    <rect x="1113" y="13" rx="0" ry="0" width="17" height="16" /> 
    <rect x="4" y="55" rx="0" ry="0" width="1185" height="3" /> 
    <rect x="6" y="63" rx="0" ry="0" width="218" height="890" /> 
    <rect x="232" y="99" rx="0" ry="0" width="224" height="124" /> 
    <rect x="233" y="252" rx="0" ry="0" width="943" height="217" /> 
    <rect x="232" y="495" rx="0" ry="0" width="940" height="215" /> 
    <rect x="475" y="98" rx="0" ry="0" width="224" height="124" /> 
    <rect x="716" y="96" rx="0" ry="0" width="224" height="124" /> 
    <rect x="956" y="95" rx="0" ry="0" width="224" height="124" /> 
    <rect x="222" y="862" rx="0" ry="0" width="971" height="39" /> 
    <rect x="235" y="881" rx="0" ry="0" width="60" height="8" /> 
    <rect x="250" y="814" rx="0" ry="0" width="107" height="4" /> 
    <rect x="494" y="813" rx="0" ry="0" width="109" height="4" /> 
    <rect x="757" y="812" rx="0" ry="0" width="102" height="5" /> 
    <rect x="1026" y="812" rx="0" ry="0" width="107" height="5" />
  </ContentLoader>
)

CoreUIDashboardLoader.metadata = {
    name: 'Charles Ejedawe',
    github: 'carlospence',
    description: 'Dashboard pages',
    filename: 'CoreUI DashboardLoader',
  }

export default CoreUIDashboardLoader